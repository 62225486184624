<template>
<div id="app" class="gochi-hand-regular w-100 position-relative">
    <TopHeader></TopHeader>
    <!-- <div id="particles"></div> -->
    <keep-alive>
        <router-view
            v-if="cachedRoutes.includes($route.name)"
            style="min-height: 100vh; width: 100%"
            class="router-view"
        ></router-view>
    </keep-alive>

    <!-- 不缓存的路由 -->
    <router-view v-if="!cachedRoutes.includes($route.name)" class="router-view" style="min-height: 100vh; width: 100%"></router-view>

    <div class="d-flex justify-content-end custom-sticky" @click="toTop">
      <button class="btn rounded-circle"><i class="fa-solid fa-chevron-up text-white"></i></button>
    </div>
    <BottomFooter></BottomFooter>
</div>
</template>

<script>
// import NavBar from '@/components/NavBar.vue';
import BottomFooter from '@/components/BottomFooter';
import TopHeader from "@/components/TopHeader.vue";
// import particlesJson from '../particles.json'

export default {
    name: 'App',
    components: {
      TopHeader,
      // NavBar,
    BottomFooter,
    },
    methods:{
        toTop(){
            window.scrollTo(0,0)
        },
    },
    data() {
        return {
            // 需要缓存的路由名称列表
            cachedRoutes: ['about', 'works', 'cat', 'cocktail', 'contact']
        };
    },
    mounted() {
    // require('particles.js')
    // // eslint-disable-next-line no-undef
    // particlesJS('particles', particlesJson)
    }

}


</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');

.gochi-hand-regular {
    font-family: "Gochi Hand", cursive;
    font-weight: 600;
    font-style: normal;
}


.custom-sticky {
    position: fixed;
    bottom: 20px; /* 调整此值来设置距离底部的距离 */
    right: 20px;
    z-index: 1000; /* 确保按钮位于顶部，不被其他元素遮挡 */
    background-color: rgba(255, 102, 102, 0.8);
    border-radius: 100px;

}


#app {
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;

}

#app a{
  text-decoration: none;
  color: inherit;
}

#app p {
    margin: 0;
    padding: 0;
}

.line_container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.line_container .line{
    width: 90%;
    height: 1px;
    background-color: #e0e0e0;
    margin-block: 50px;
}

.red-bottom {
    width: fit-content;
    border-bottom: 3px solid #FF6666;
}






</style>
