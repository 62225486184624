<template>
    <div class="container-lg gochi-hand-regular">
        <div class="row d-flex flex-row text-center m-0 p-2 p-md-0 cat-card">
            <div class="col-2 d-flex justify-content-start p-3"><img src="@/assets/cocktail/cocktail_avatar.png" class="img-fluid object-fit-contain"></div>
            <div class="col-8 d-flex align-items-center justify-content-center d-flex flex-column">
                <h1>COCKTAIL</h1>
                <img src="@/assets/cocktail/cocktail_avatar_3.png" class="img-fluid object-fit-contain w-50 ">
            </div>
            <div class="col-2 d-flex justify-content-end p-3"><img src="@/assets/cocktail/cocktail_avatar_2.png" class="img-fluid object-fit-contain"></div>
        </div>

        <div class="line_container"><div class="line my-5"></div></div>

        <Waterfall :list="cockTailList" :breakpoints="breakpoints">
            <template #item="{item, url, index}">
                <div class="card" id="cocktail-pic" @click="openModal(item)">
                    <LazyImg :url=item.img class="card-img-top"/>
                    <div class="card-body ">
                        <h5 class="card-title gochi-hand-regular fs-4">{{ item.name }}</h5>
                        <p class="text-muted text-center my-3 recipe">{{ item.recipe }}</p>
                        <p class="text-muted mb-0"><i class="bi bi-geo-alt me-1"></i>{{ item.location }}</p>
                    </div>
                    <div class="card-footer text-body-secondary text-center ">
                        {{ item.date }}
                    </div>
                </div>
            </template>
        </Waterfall>

        <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modal">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="card w-100 border-0 rounded-3 p-0 m-0" id="cocktail-pic">
                    <div class="row rounded-3 p-0 m-0">
                        <div class="col-6 rounded-3 p-0 m-0"><img :src=cocktailItem.img class="img-fluid rounded-start-3" /></div>
                        <div class="col-6  p-3 d-flex flex-column flex-content-center justify-content-around">
                            <h5 class="card-title gochi-hand-regular fs-4">{{ cocktailItem.name }}</h5>
                            <p class="text-muted text-center my-3 recipe m-auto">{{ cocktailItem.recipe }}</p>
                            <div>
                                <p class="text-muted mb-0"><i class="bi bi-geo-alt me-1"></i>{{ cocktailItem.location }}</p>
                                <p class="text-muted mb-0"><i class="bi bi-calendar-date me-1"></i>{{ cocktailItem.date }}</p>
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { LazyImg, Waterfall } from 'vue-waterfall-plugin'
import 'vue-waterfall-plugin/dist/style.css'

export default {

    components: {
        Waterfall,LazyImg
    },
    data() {
        return {
            cockTailList: [
                
                {name:"黄",recipe:"芒果 / 凤梨 / 木姜子 / 话梅 / 朗姆酒",img:require('@/assets/cocktail/241002_1.jpg'),date:"2024-10-02",location:"Cupee, 广州市增城区荔城街荔乡路5号"},
                {name:"绿",recipe:"抹茶 / 日本柚子 / 朗姆酒",img:require('@/assets/cocktail/241002_2.jpg'),date:"2024-10-02",location:"Cupee, 广州市增城区荔城街荔乡路5号"},
                {name:"晚霞不晚",recipe:"超级西柚汁 / 阿佩罗橙酒 / 玫瑰 / 杜松子 / 柠檬",img:require('@/assets/cocktail/241002_3.jpg'),date:"2024-09-24",location:"Moment, 广州市增城区荔城街园圃路41号"},
                {name:"小王子的玫瑰2.0",recipe:"云南玫瑰洛神花老卤酒 / 荔枝甜酒 / 柠檬",img:require('@/assets/cocktail/241002_4.jpg'),date:"2024-09-24",location:"Moment, 广州市增城区荔城街园圃路41号"},
                {name:"星期八",recipe:"巨峰葡萄 / 自制红糖伯爵茶利口酒 / 黑加仑 / 柠檬",img:require('@/assets/cocktail/240924.jpg'),date:"2024-09-24",location:"Moment, 广州市增城区荔城街园圃路41号"},

                {name:"Core Philosophy & Adult Entertainment",recipe:"Gin / Elderflower / Melon / Apple Core / Jasmine Pearl Tea / Apple Vinegar / Soda; \n The Macallan 12yr Whisky / Passionfruit Wine / Apricot / Soda;",img:require('@/assets/cocktail/240622_1.jpg'),date:"2024-06-22",location:"The Public, Surrey Street, Sheffield, S1 2LG"},
                {name:"Daiq in Business",recipe:"Plantain fat-washed Appleton Rum / Honey / Allspice Wray and Nephew Tincture / Grapefruit Salt",img:require('@/assets/cocktail/240622_2.jpg'),date:"2024-06-22",location:"The Public, Surrey Street, Sheffield, S1 2LG"},
                {name:"Grape Expectation",recipe:"Barsol Pisco / Bergamot / Split Milk Clarified Grape Juice",img:require('@/assets/cocktail/240622_3.jpg'),date:"2024-06-22",location:"The Public, Surrey Street, Sheffield, S1 2LG"},

                {name:"Speed Drive & Corn Supermacy",recipe:"「Nitro Peach」double gin / Cocchi Rosa / Cardamom / Champagne Acid; \n Cuba 3yr Rum / Corn / Banana;",img:require('@/assets/cocktail/240612_1.jpg'),date:"2024-06-12",location:"The Public, Surrey Street, Sheffield, S1 2LG"},
                {name:"Sunny Disposition",recipe:"Chase Vodka / Bergamot / Blackcurrant / Pomegranate and Pink Peppercorn Shrub / Lemon / Prosecco;",img:require('@/assets/cocktail/240612_2.jpg'),date:"2024-06-12",location:"The Public, Surrey Street, Sheffield, S1 2LG"},

                {name:"NewYork Sour",recipe:"Whisky / Port / Lemon / Syrup;",img:require('@/assets/cocktail/240420.jpg'),date:"2024-04-20",location:"Homemade"},
                {name:"BLINKER",recipe:"blending rye / grapefruit / blending rye;",img:require('@/assets/cocktail/240414.jpg'),date:"2024-04-14",location:"BLINKER 70 Spring Gardens, Manchester M2 2BQ"},
                {name:"Pink Peach Tree & Golden Delicious",recipe:"The Macallan DC 12 Scotch / Peach / montillado Sherry / Bitters; \n Calvados / Honey / Lemon;",img:require('@/assets/cocktail/240413.jpg'),date:"2024-04-13",location:"SCHOFIELD's Sunlight House, 3 Little Quay Street, Manchester, M3 3JZ"},
                {name:"Blood Orange and YUZU Cosmo & Lyche and Cranberry Fizz",recipe:"Malfy arancia / YUZU sake / Lime / Vanilla / Cranberry; \n Kwai feh lyche / cranberry / lemon / prosecco;",img:require('@/assets/cocktail/240407.jpg'),date:"2024-04-07",location:"Proove, 16-17 ORCHARD SQ, SHEFFIELD, S1 2FB"},
                {name:"BLOCKBUSTER",recipe:"GIN / Fennel / Plum wine / peach / Bubbles;",img:require('@/assets/cocktail/240406.jpg'),date:"2024-04-06",location:"Murder, 36 HANWAY STREET, LONDON, W1T 1UP"},
                {name:"MANGO",recipe:"Toki Whisky / Lacto Fermented Roasted / Green Mango / Ancho Reyes Verde;",img:require('@/assets/cocktail/240405.jpg'),date:"2024-04-05",location:"SOMA, 14 Denman St, London W1D 7HJ"},
                {name:"Bee's knees",recipe:"Gin / Lemon / Honey;",img:require('@/assets/cocktail/240207.jpg'),date:"2024-02-07",location:"Homemade"},
                {name:"PALOMA & RASPBERRY",recipe:"Grapefruit / Tequila / Lime \n Raspvetty / Gin / Bubbles;",img:require('@/assets/cocktail/240131.jpg'),date:"2024-01-31",location:"PASSING FANCIES, 30 Gibb Street, The Custard Factory, B94BF "},
                {name:"Plum Together",recipe:"Roku Japanese gin / Akashi-Tai plum sake, cherry syrup / tartaric acid / plum bitters / Tartarashi chilli rim;",img:require('@/assets/cocktail/240118.jpg'),date:"2024-01-18",location:"The Public, Surrey Street, Sheffield, S1 2LG"},
                {name:"Million Dollar Bounty",recipe:"Coconut oil-washed Johnny Walker Black Label whisky / Koko Kanu rum / Giffard Crème de Cacao / Miso demerara syrup / chocolate bitters",img:require('@/assets/cocktail/231105.jpg'),date:"2023-11-05",location:"The Public, Surrey Street, Sheffield, S1 2LG"},
                {name:"Pornstart Matini",recipe:"Smirnoff vanilla vodka, passoa / pineapple juice / lime juice / passionfruit puree / sugarsyrup / served with a shot of Prosecco;",img:require('@/assets/cocktail/231117.jpg'),date:"2023-11-17",location:"Colonel Porter's Emporium, Milburn House, Dean St, Newcastle upon Tyne NE1 1LF"},
                {name:"Yoko Ob-yes",recipe:"Galangal / Agave / Genmaicha Tea / Yuzu Sake / Suze / Acid / Peated Olive Oil;",img:require('@/assets/cocktail/231223.jpg'),date:"2023-12-23",location:"The Absent Ear, Brunswick St, Glasgow G1 1TF"},
                {name:"Nola",recipe:"",img:require('@/assets/cocktail/231227.jpg'),date:"2023-12-27",location:"Nola, 39 Union St, Dundee DD1 4BS"},
                {name:"Draffens",recipe:"",img:require('@/assets/cocktail/231228.jpg'),date:"2023-12-28",location:"Draffens, Couttie's Wynd, Dundee DD1 4ET"},
                {name:"Long Island iced tea",recipe:"Vodka / Gin / Tequila / Rum / Cointreau / Lime / Cola / Syrup",img:require('@/assets/cocktail/231020.jpg'),date:"2023-10-22",location:"Homemade"},
                {name:"Ice Breaker",recipe:"Agave / Cointreau / Grapefruit / Grenadine Syrup;",img:require('@/assets/cocktail/231017.jpg'),date:"2023-10-17",location:"Homemade"},
                {name:"Whiskey sour",recipe:"Whisky / Lemon / Syrup",img:require('@/assets/cocktail/231005.jpg'),date:"2023-10-05",location:"Homemade"},

                
            ],
            breakpoints: {
                0: {
                    rowPerView: 1,
                },
                576: {
                    rowPerView: 2,
                },
                768: {
                    rowPerView: 3,
                },
                992: {
                    rowPerView: 4,
                },
                1200: {
                    rowPerView: 5,
                },
                1400: {
                    rowPerView: 5,
                }
            },
            cocktailItem:{name:"黄",recipe:"芒果 / 凤梨 / 木姜子 / 话梅 / 朗姆酒",img:require('@/assets/cocktail/241002_1.jpg'),date:"2024-10-02",location:"Cupee, 广州市增城区荔城街荔乡路5号"}
        };
    },
    methods: {
        openModal(item) {
            // Populate the modal data with the clicked item
            this.cocktailItem = { ...item };
            console.log(this.cocktailItem);
            

            // Ensure the modal is shown
            const modalElement = document.getElementById('modal');
            if (modalElement) {
                const modal = new window.bootstrap.Modal(modalElement);
                modal.show();
            }
        },
    }
}
</script>

<style lang="css" scoped>
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');

.gochi-hand-regular {
    font-family: "Gochi Hand", cursive;
    font-weight: 600;
    font-style: normal;
}

#cocktail-pic {
    transition: 0.1s;
}

#cocktail-pic:hover {
    border: 2px solid #6e6d7a;
    cursor: pointer;
}


.recipe {
    white-space: pre-line; /* 或者使用 pre-wrap */
}

.cat-card {
    background-color: #1f2534;
    color: white;
}
</style>