<template>
    <div class="container">
<!--        <div class="row d-flex flex-row text-center m-0 cat-card card border-0">-->
<!--            <div class="col-3 d-none col-md-3 d-md-block m-0 p-0">-->
<!--                <img src="@/assets/kitty/cat_avatar.png" class="img-fluid object-fit-contain">-->
<!--            </div>-->
<!--            <div class="col-12 col-md-6 d-flex align-items-center justify-content-around d-flex flex-column">-->
<!--                <h1>{{ $t('message.cat.name') }}</h1>-->
<!--                <div class="row w-100 mt-2 d-flex justify-content-center">-->
<!--                    <div class="col-4 col-md-3 card p-3 rounded-4">-->
<!--                        <h5>{{ $t('message.cat.age') }}</h5>-->
<!--                        <p class="text-muted small">{{ $t('message.cat.age_title') }}</p>-->
<!--                    </div>-->
<!--                    <div class="col-4 col-md-3 card p-3 rounded-4 mx-md-2">-->
<!--                        <h5>{{ $t('message.cat.weight') }}</h5>-->
<!--                        <p class="text-muted small">{{ $t('message.cat.weight_title') }}</p>-->
<!--                    </div>-->
<!--                    <div class="col-4 col-md-3 card p-3 rounded-4">-->
<!--                        <h5>{{ $t('message.cat.sex') }}</h5>-->
<!--                        <p class="text-muted small">{{ $t('message.cat.sex_title') }}</p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-3 d-none col-md-3 d-md-block d-flex justify-content-end p-0">-->
<!--                <img src="@/assets/kitty/cat_avatar_2.png" class="img-fluid object-fit-contain">-->
<!--            </div>-->
<!--        </div>-->

        <div class="row cat-card p-2 border-0 rounded-3">
            <div class="col-4 col-md-2 d-flex justify-content-center mx-auto">
                <img src="@/assets/kitty/cat_avatar_3.png" class="img-fluid object-fit-contain">
            </div>
            <div class="col-12 col-md-8 d-flex align-items-center justify-content-around d-flex flex-column">
                <h1>🐱{{ $t('message.cat.name') }}</h1>
                <div class="row w-100 mt-2 d-flex justify-content-center">
                    <div class="col-4 col-md-3 card p-3 rounded-4">
                        <h5 class="fs-3">{{ $t('message.cat.age') }}</h5>
                        <p class="text-muted ">{{ $t('message.cat.age_title') }}</p>
                    </div>
                    <div class="col-4 col-md-3 card p-3 rounded-4 mx-md-2">
                        <h5 class="fs-3">{{ $t('message.cat.weight') }}</h5>
                        <p class="text-muted ">{{ $t('message.cat.weight_title') }}</p>
                    </div>
                    <div class="col-4 col-md-3 card p-3 rounded-4">
                        <h5 class="fs-3">{{ $t('message.cat.sex') }}</h5>
                        <p class="text-muted ">{{ $t('message.cat.sex_title') }}</p>
                    </div>
                </div>
            </div>
            <div class="col d-none col-md-2 d-md-block d-flex justify-content-center">
                <img src="@/assets/kitty/cat_avatar_4.png" class="img-fluid object-fit-contain">
            </div>
        </div>


        <div class="line_container"><div class="line my-5"></div></div>



        <Waterfall :list="kittyList" :breakpoints="breakpoints">
            <template #item="{item, url, index}">
                <div class="card" id="cat-pic" @click="openModal(item)">
                    <LazyImg :url="url" class="card-img-top"/>
                    <div class="card-footer text-body-secondary text-center gochi-hand-regular">
                        {{ item.date }}
                    </div>
                </div>
            </template>
        </Waterfall>

        <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modal">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="card" id="cat-pic" @click="openModal(item)">
                    <img :src="catItem.src" class="card-img-top"/>
                    <div class="card-footer text-body-secondary text-center gochi-hand-regular">
                        {{ catItem.date }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { LazyImg, Waterfall } from 'vue-waterfall-plugin'
import 'vue-waterfall-plugin/dist/style.css'

export default {
    components: {
        Waterfall,LazyImg
    },

    data() {
        return {
            kittyList: [],
            breakpoints: {
                0: {
                    rowPerView: 1,
                },
                576: {
                    rowPerView: 2,
                },
                768: {
                    rowPerView: 3,
                },
                992: {
                    rowPerView: 4,
                },
                1200: {
                    rowPerView: 5,
                },
                1400: {
                    rowPerView: 5,
                }
            },
            catItem:{date: "", src: ""}
        };
    },
    methods: {
        openModal(item) {
            // Populate the modal data with the clicked item
            this.catItem = { ...item };
            

            // Ensure the modal is shown
            const modalElement = document.getElementById('modal');
            if (modalElement) {
                const modal = new window.bootstrap.Modal(modalElement);
                modal.show();
            }
        },
    },
    created() {
        //使用require.context动态导入图片
        const files = require.context('@/assets/kitty', false, /\.jpg$/);
        // 使用正则表达式匹配文件名中的日期部分，并将其转换为指定格式的日期字符串
        const dateRegex = /(\d{2})(\d{2})(\d{2})\.jpg/;
        files.keys().forEach(key => {
            const fileName = key.match(dateRegex);
            if (fileName) {
                const year = '20' + fileName[1];
                const month = fileName[2];
                const day = fileName[3];
                const dateString = `${year}-${month}-${day}`;

                // 添加到list数组中
                this.kittyList.unshift({
                    date: dateString,
                    src: files(key)
                });
            }
        });
    }
}
</script>

<style lang="css" scoped>
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');

.gochi-hand-regular {
    font-family: "Gochi Hand", cursive;
    font-weight: 400;
    font-style: normal;
}

#cat-pic {
    transition: 0.1s;
    cursor: pointer;
}

#cat-pic:hover {
    border: 2px solid #6e6d7a;
    cursor: pointer;
}

.cat-card {
    background-color: #e2dad1;
}
</style>