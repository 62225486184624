import Vue from 'vue'
import App from './App.vue'
import router from "@/router"


// import 'bootstrap/dist/css/bootstrap.css' //引用bootstrap的样式
window.bootstrap = require('bootstrap');
// import 'bootstrap/dist/js/bootstrap.bundle.min.js' // 引用bootstrap的js

import 'bootstrap/dist/css/bootstrap.css' // 引用bootstrap的样式
// import 'bootstrap/dist/js/bootstrap.bundle.min.js' // 引用bootstrap的js


import 'bootstrap-icons/font/bootstrap-icons.css'






// import particles from 'particles.js'
// Vue.use(particles)

Vue.config.productionTip = false

// import {
//   Button, Field, Tabbar, TabbarItem, Swipe, SwipeItem, Lazyload, Search,
//   Card,Tab, Tabs,SwipeCell,NavBar,Cell,CellGroup,Col,Row,Dialog,Tag } from 'vant'
// import 'vant/lib/index.css'
// Vue.use(Button)
// Vue.use(Field)
// Vue.use(Tabbar)
// Vue.use(TabbarItem)
// Vue.use(Swipe)
// Vue.use(SwipeItem)
// Vue.use(Search)
// Vue.use(Card)
// Vue.use(Tab);
// Vue.use(Tabs);
// Vue.use(SwipeCell);
// Vue.use(NavBar);
// Vue.use(Cell);
// Vue.use(CellGroup);
// Vue.use(Col);
// Vue.use(Row);
// Vue.use(Lazyload);
// Vue.use(Dialog);
// Vue.use(Tag);

import i18n from './i18n' // 导入 i18n 实例


// 引入 animate.css
import 'animate.css/animate.compat.css'
import { WOW } from 'wowjs'

// 将 WOW 添加到 Vue 的原型上，使其在所有组件中可用
Vue.prototype.$wow = new WOW({
  boxClass: 'wow',
  animateClass: 'animated',
  offset: 0,
  mobile: true,
  live: true
});

import VueTypedJs from 'vue-typed-js'

Vue.use(VueTypedJs)

new Vue({
  router,
  i18n,
  render: h => h(App),
  mounted() {
    this.$nextTick(() => {
      this.$wow.init();
    });
  }
}).$mount('#app')