<template>
    <div id="HomeView" class="container">

        <div id="intro" class="row wow fadeIn" data-wow-duration="2s">
            <div class="col-8 col-sm-7 col-md-5 col-lg-4 mx-auto">
                <div class="avatar-container">
                    <img src="../assets/avatar3.png" class="img-fluid default-avatar">
                    <img src="../assets/avatar2.png" class="img-fluid hover-avatar">
                </div>

            </div>
            <div class="col-12 col-md-7 d-flex align-items-center px-3">
                <div id="typed" class="ibm-plex-mono-regular"></div>
            </div>

                    <!-- <van-button color="#FF6464" class="dl-btn float-end" @click="downloadPDF" >下载简历</van-button> -->

        </div>

        <div class="education wow fadeIn" data-wow-duration="2s">
            <div class="title mt-5">
                <h3 class="px-5 mt-5 my-3">{{ $t('message.about.edu.title') }}</h3>
            </div>
            <div id="educations" class="row mx-4 mt-5 text-center d-flex justify-content-center mx-0 gap-5 wow animate__fadeIn" style="cursor: pointer;"  @click="toURL('https://www.sheffield.ac.uk/undergraduate/courses/2025/computer-science-software-engineering-beng')">
                <div class="col-10 col-md-5 row d-flex justify-content-center mx-0">
                    <div class="col-3 mb-1"><img src="../assets/logo/tuoslogo.png" class="img-fluid"></div>
                    <h3 class="fs-3 fw-bold">{{ $t('message.about.edu.name') }}</h3>
                    <p><i class="bi bi-star-fill mx-1"></i>{{ $t('message.about.edu.bachelor_title') }} (2020 - 2023)</p>
                    <p><i class="bi bi-star-fill mx-1"></i>{{ $t('message.about.edu.bachelor_degree') }}</p>
                </div>

                <div class="col-10 col-md-5 row d-flex justify-content-center mx-0" style="cursor: pointer;"  @click="toURL('https://www.sheffield.ac.uk/postgraduate/taught/courses/2025/advanced-computer-science-msc')">
                    <div class="col-3 mb-1"><img src="../assets/logo/UOSLogo.png" class="img-fluid"></div>
                    <h3 class="fs-3 fw-bold">{{ $t('message.about.edu.name') }}</h3>
                    <p><i class="bi bi-star-fill mx-1"></i>{{ $t('message.about.edu.master_title') }} (2023 - 2024)</p>
                    <p><i class="bi bi-star-fill mx-1"></i>{{ $t('message.about.edu.master_degree') }}</p>
                </div>
            </div>
        </div>

<!--        <div class="project wow fadeIn" data-wow-duration="2s">-->
<!--            <div class="title mt-5 ">-->
<!--                <h3 class="px-5 mt-5 my-3">{{ $t('message.about.focus.title') }}</h3>-->
<!--            </div>-->
<!--            <div id="works-contents" class="row mx-4 mt-5">-->
<!--                <div class="col-12 col-md-6 col-xxl-3">-->
<!--                    <WorkContent :img="require('@/assets/logo/web.png')"-->
<!--                                 :title="$t('message.about.focus.web_development.title')">-->
<!--    &lt;!&ndash;                    <template v-slot:content>{{ $t('message.home.focus.web_development.content') }}</template>&ndash;&gt;-->
<!--                    </WorkContent>-->
<!--                </div>-->
<!--                <div class="col-12 col-md-6 col-xxl-3">-->
<!--                    <WorkContent :img="require('@/assets/logo/mobile.png')"-->
<!--                                 :title="$t('message.about.focus.mobile_development.title')">-->
<!--    &lt;!&ndash;                    <template v-slot:content>{{ $t('message.home.focus.mobile_development.content') }}</template>&ndash;&gt;-->
<!--                    </WorkContent>-->
<!--                </div>-->
<!--                <div class="col-12 col-md-6 col-xxl-3">-->
<!--                    <WorkContent :img="require('@/assets/logo/ai.png')"-->
<!--                                 :title="$t('message.about.focus.ai.title')">-->
<!--    &lt;!&ndash;                    <template v-slot:content>{{ $t('message.home.focus.ai.content') }}</template>&ndash;&gt;-->
<!--                    </WorkContent>-->
<!--                </div>-->
<!--                <div class="col-12 col-md-6 col-xxl-3">-->
<!--                    <WorkContent-->
<!--                        :img="require('@/assets/logo/repair.png')"-->
<!--                        :title="$t('message.about.focus.test.title')">-->

<!--    &lt;!&ndash;                    <template v-slot:content>{{ $t('message.home.focus.test.content') }}</template>&ndash;&gt;-->
<!--                    </WorkContent>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <div class="stack wow fadeIn" data-wow-duration="2s">
            <div class="title mt-5">
                <h3 class="px-5 mt-5 my-3">{{ $t('message.about.stack.title') }}</h3>
            </div>
            <div id="stack-contents" class="row px-4 mt-3 gy-2 gx-3">
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/git.png')"
                                 stackName="Git" bgc="#f05032"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/android.png')"
                                 stackName="ANDROID" bgc="#3ddc84"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/linux.png')"
                                 stackName="LINUX" bgc="#fcc624"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/python.png')"
                                 stackName="PYTHON" bgc="#3776ab"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/ejs.png')"
                                 stackName="EJS" bgc="#239120"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/html.png')"
                                 stackName="HTML" bgc="#239120"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/css.png')"
                                 stackName="CSS" bgc="#239120"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2 ">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/java-script.png')"
                                 stackName="JAVASCRIPT" bgc="#f7df1e"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/node-js.png')"
                                 stackName="NODE.JS" bgc="#43853d"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2 ">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/java.png')"
                                 stackName="JAVA" bgc="#ed8b00"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/kotlin.png')"
                                 stackName="KOTLIN" bgc="#0095d5"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/ruby.png')"
                                 stackName="RUBY" bgc="#cc342d"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/vue.png')"
                                 stackName="VUE.JS" bgc="#35495e"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/bootstrap.png')"
                                 stackName="BOOTSTRAP" bgc="#563d7c"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/mysql.png')"
                                 stackName="MYSQL" bgc="#00000f"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/postgresql.png')"
                                 stackName="POSTGRESQL" bgc="#316192"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/mongoDb.png')"
                                 stackName="MONGODB" bgc="#499D4A"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/nginx.png')"
                                 stackName="NGINX" bgc="#009639"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/haskell.png')"
                                 stackName="HASKELL" bgc="#8F4E8B"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/opengl.png')"
                                 stackName="OpenGL" bgc="#4a7ca9"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/swift.png')"
                                 stackName="Swift" bgc="#fc3f1b"></SingleStack>
                </div>
                <div class="col-6 col-sm-3 col-md-2">
                    <SingleStack :imgSrc="require('@/assets/stackLogo/ios.png')"
                                 stackName="iOS" bgc="#000000"></SingleStack>
                </div>
            </div>
        </div>


    </div>
</template>

<script
    src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBJtnXvWVd_aeGOUN3Ei6k1q3_iOv142TE&libraries=geometry,places"
    async defer>
</script>

<script>
import WorkContent from '@/components/WorkContent';
import SingleStack from '@/components/SingleStack';
const today = require('../utils/today')
import { postVisitorAPI,pushVisitorAPI } from '@/api';
import Typed from 'typed.js'


export default {
    components: {
        WorkContent,
        SingleStack
    },
    data() {
        return {
            typed: null,
            currentLine: 0,
            language: this.$i18n.locale,
        }
    },
    async created() {
        // 等待Google Maps API加载完成
        await this.loadGoogleMapsAPI();
        const body = await this.getLocation();
        await this.pushNotification(body);
    },
    async mounted() {
        this.startTyping()

        // Watch for language changes and reset the typing effect
        this.$watch('$i18n.locale', (newLocale, oldLocale) => {
            this.onLanguageChange(newLocale);
        });
    },
    methods: {
        onLanguageChange(newLocale) {
            // Handle language change

            // Reset the typing effect
            this.currentLine = 0; // Reset current line to start from the beginning
            this.typed.destroy(); // Destroy the current Typed instance to clear the text
            this.typed.el.innerHTML = ''; // Clear any existing text

            this.startTyping(); // Restart typing effect with updated language
        },
        getLines() {
            return [
                '>>> import tenenzzzz ^300',
                this.$t('message.about.hello'),
                '>>> tenenzzzz.job ^300',
                this.$t('message.about.job'),
                '>>> tenenzzzz.interests ^300',
                this.$t('message.about.interests'),
                '>>> tenenzzzz.education ^300',
                this.$t('message.about.education')
            ];
        },
        startTyping() {
            this.typed = new Typed('#typed', {
                strings: [''],
                typeSpeed: 100,
                showCursor: false,
                cursorChar: '|',
                onComplete: () => {
                    this.typeNextLine()
                }
            })
        },
        typeNextLine() {
            const lines = this.getLines();  // 动态获取当前的翻译内容

            if (this.currentLine < lines.length) {
                const lineText = lines[this.currentLine];

                // 创建新的行元素
                const lineElement = document.createElement('span');
                this.typed.el.appendChild(lineElement);

                // 创建新的光标元素
                const cursorElement = document.createElement('span');
                cursorElement.className = 'typed-cursor typed-cursor--blink'; // 添加光标样式类
                cursorElement.textContent = '|'; // 光标字符
                this.typed.el.appendChild(cursorElement);

                if (lineText.startsWith('>>>')) {
                    lineElement.className = 'fw-bold';

                    // 分割 ">>>" 和剩余的内容
                    const commandPrefix = '>>> ';
                    const remainingText = lineText.substring(commandPrefix.length);

                    // 立即显示 ">>>"
                    const prefixElement = document.createElement('span');
                    prefixElement.textContent = commandPrefix;
                    lineElement.appendChild(prefixElement);

                    setTimeout(() => {
                        // 使用 Typed.js 动画效果显示剩余部分
                        const typingElement = document.createElement('span');
                        lineElement.appendChild(typingElement);

                        new Typed(typingElement, {
                            strings: [remainingText],
                            typeSpeed: 50,
                            showCursor: false, // 隐藏 Typed.js 默认光标
                            onComplete: () => {
                                cursorElement.style.display = 'none'; // 隐藏手动创建的光标
                                this.currentLine++;
                                this.typeNextLine();
                            }
                        });
                    }, 300);  // 延迟 300 毫秒

                } else {
                    cursorElement.style.display = 'none';

                    // 使用 i18n 的翻译函数显示回答内容
                    lineElement.textContent = lineText;  // 确保内容是通过 i18n 动态更新的

                    // 设置一个短暂的延迟后显示下一个，模拟思考或延迟效果
                    setTimeout(() => {
                        this.currentLine++;
                        this.typeNextLine();
                    }, 0);  // 无延迟
                }

                // 插入换行符
                const brElement = document.createElement('br');
                this.typed.el.appendChild(brElement);
            }
        },

        downloadPDF() {
            const fileUrl = '/Zhicong_CV_zh.pdf'; // 替换为你的静态 PDF 文件路径
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = '蒋志聪_中文简历.pdf'; // 设置下载文件的名称
            link.click();
        },
        async loadGoogleMapsAPI() {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAEWKP6g2pWXqE4kKDnM_sUsw6qmI3_tCc&libraries=geometry,places`;
                script.async = true;
                script.defer = true;
                script.onload = resolve;
                script.onerror = reject;
                document.head.appendChild(script);
            });
        },

        async getLocation() {
            let latitude = 0;
            let longitude = 0;
            let location = "未知地址";

            // 创建一个新的地理编码器实例
            const geocoder = new google.maps.Geocoder();

            try {
                // 检查浏览器是否支持Geolocation API
                if ("geolocation" in navigator) {
                    // 支持Geolocation
                    const position = await new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(resolve, reject);
                    });

                    // 获取经度和纬度
                    latitude = position.coords.latitude;
                    longitude = position.coords.longitude;

                    // 创建一个 LatLng 对象
                    const latLng = new google.maps.LatLng(latitude, longitude);

                    // 执行逆地理编码
                    const results = await new Promise((resolve, reject) => {
                        geocoder.geocode({ location: latLng }, function (results, status) {
                            if (status === google.maps.GeocoderStatus.OK) {
                                resolve(results);
                            } else {
                                reject("逆地理编码失败：" + status);
                            }
                        });
                    });

                    if (results[0]) {
                        // 获取第一个结果的地理位置信息
                        location = results[0].formatted_address;
                        console.log("地理位置信息：", location);
                        return `${latitude},${longitude}  ${location}`;
                    } else {
                        throw new Error("未找到结果");
                    }
                } else {
                    // 不支持Geolocation
                    throw new Error("浏览器不支持Geolocation API");
                }
            } catch (error) {
                console.error(error);
                return "无法获取位置信息";
            }
        },


        async pushNotification(body){
            const params = new URLSearchParams();
            params.append('title', "新的访问者");
            params.append('body', body);
            params.append('icon', "https://static.tvtropes.org/pmwiki/pub/images/beluga.jpg");
            params.append('url', "http://terenzzzz.cn");
            params.append('sound', "calypso");
            const res = await pushVisitorAPI(params)
            console.log(res);
        },
        toURL(url){
            window.open(url, '_blank');
        }
    }
}
</script>

<style lang="css" scoped>
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=ZCOOL+KuaiLe&display=swap');
.ibm-plex-mono-regular {
    font-family: "IBM Plex Mono", monospace;
    font-weight: 400;
    font-style: normal;
}

/* 确保容器相对定位 */
.avatar-container {
    position: relative;
    width: 100%;
}

/* 默认图像 */
.default-avatar {
    display: block; /* 确保默认显示 */
    width: 100%; /* 根据需要调整宽度 */
    transition: opacity 0.3s ease-in-out; /* 添加过渡效果 */
}

/* 悬停图像初始隐藏 */
.hover-avatar {
    position: absolute; /* 绝对定位，确保两张图像重叠 */
    top: 0;
    left: 0;
    width: 100%; /* 根据需要调整宽度 */
    opacity: 0; /* 初始状态下隐藏悬停图像 */
    transition: opacity 0.3s ease-in-out; /* 添加过渡效果 */
}

/* 悬停时隐藏默认图像 */
.avatar-container:hover .default-avatar {
    opacity: 0; /* 悬停时隐藏默认图像 */
}

/* 悬停时显示悬停图像 */
.avatar-container:hover .hover-avatar {
    opacity: 1; /* 悬停时显示悬停图像 */
}


#typed {
    white-space: pre-wrap;
    word-break: break-word;
}
.title{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title h3{
    border-bottom: 3px solid #FF6666;
}

.img-container {
    position: relative;
    padding-bottom: 100%; /* 创建一个正方形容器 */
}

.img-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

</style>